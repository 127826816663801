import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box 
      sx={{ 
        p: 2, 
        textAlign: { xs: 'center', md: 'left' }, 
        borderTop: 1, 
        borderColor: 'divider', 
        background: 'linear-gradient(120deg, #3DC2EC 70%, #402E7A 30%)',
        color: '#FFFFFF',
        position: 'fixed',
        bottom: 0,
        width: '100%'
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: 'Montserrat, Arial', fontStyle: 'italic' }}>
        {t('footerText')}
      </Typography>
    </Box>
  );
};

export default Footer;

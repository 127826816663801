import React, { useState } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ScientificEvents = ({ formData = [], handleAddField, handleChange, handleSaveSection }) => {
  const [jsonInput, setJsonInput] = useState('');
  const [data, setData] = useState(formData);

  const handleJsonInput = (event) => {
    setJsonInput(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? '' : date.toISOString().split('T')[0]; // Formatea la fecha a YYYY-MM-DD
  };

  const handleProcessJson = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (Array.isArray(parsedData)) {
        const formattedData = parsedData.map(evento => ({
          ...evento,
          fecha_inicio: formatDate(evento.fecha_inicio),
          fecha_fin: formatDate(evento.fecha_fin)
        }));
        setData((prevData) => [...prevData, ...formattedData]);
        setJsonInput(''); // Clear the input after processing
      } else {
        alert('JSON inválido. Por favor, asegúrate de que sea un arreglo de objetos.');
      }
    } catch (error) {
      console.error('Invalid JSON', error);
      alert('JSON inválido. Por favor, verifica la sintaxis.');
    }
  };

  const handleSave = () => {
    handleSaveSection('eventos_cientificos', data);
  };

  const handleAddParticipant = (eventIndex) => {
    const updatedData = [...data];
    if (!updatedData[eventIndex].participantes) {
      updatedData[eventIndex].participantes = [];
    }
    updatedData[eventIndex].participantes.push({ nombre: '', rol_evento: '' });
    setData(updatedData);
  };

  const handleAddInstitution = (eventIndex) => {
    const updatedData = [...data];
    if (!updatedData[eventIndex].instituciones_asociadas) {
      updatedData[eventIndex].instituciones_asociadas = [];
    }
    updatedData[eventIndex].instituciones_asociadas.push({ nombre_institucion: '', tipo_producto: '' });
    setData(updatedData);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Eventos Científicos
        </Typography>
        <Divider />
      </Grid>
      <Box
        sx={{
          maxHeight: '60vh', // Adjust the height as needed
          overflowY: 'auto',
          width: '100%',
        }}
      >
        {data.map((evento, index) => (
          <React.Fragment key={index}>
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nombre del Evento"
                  value={evento.nombre_evento || ''}
                  onChange={(e) => handleChange('eventos_cientificos', 'nombre_evento', e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tipo de Evento"
                  value={evento.tipo_evento || ''}
                  onChange={(e) => handleChange('eventos_cientificos', 'tipo_evento', e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Ámbito"
                  value={evento.ambito || ''}
                  onChange={(e) => handleChange('eventos_cientificos', 'ambito', e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Fecha de Inicio"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={evento.fecha_inicio || ''}
                  onChange={(e) => handleChange('eventos_cientificos', 'fecha_inicio', e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Fecha de Fin"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={evento.fecha_fin || ''}
                  onChange={(e) => handleChange('eventos_cientificos', 'fecha_fin', e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Lugar"
                  value={evento.lugar || ''}
                  onChange={(e) => handleChange('eventos_cientificos', 'lugar', e.target.value, index)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Instituciones Asociadas
                </Typography>
                <IconButton onClick={() => handleAddInstitution(index)}>
                  <AddIcon />
                </IconButton>
              </Grid>
              {evento.instituciones_asociadas && evento.instituciones_asociadas.map((institucion, instIndex) => (
                <React.Fragment key={instIndex}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Institución Asociada"
                      value={institucion.nombre_institucion || ''}
                      onChange={(e) =>
                        handleChange('eventos_cientificos', `instituciones_asociadas[${instIndex}].nombre_institucion`, e.target.value, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Tipo de Producto"
                      value={institucion.tipo_producto || ''}
                      onChange={(e) =>
                        handleChange('eventos_cientificos', `instituciones_asociadas[${instIndex}].tipo_producto`, e.target.value, index)
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Participantes
                </Typography>
                <IconButton onClick={() => handleAddParticipant(index)}>
                  <AddIcon />
                </IconButton>
              </Grid>
              {evento.participantes && evento.participantes.map((participante, partIndex) => (
                <React.Fragment key={partIndex}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Nombre del Participante"
                      value={participante.nombre || ''}
                      onChange={(e) =>
                        handleChange('eventos_cientificos', `participantes[${partIndex}].nombre`, e.target.value, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Rol en el Evento"
                      value={participante.rol_evento || ''}
                      onChange={(e) =>
                        handleChange('eventos_cientificos', `participantes[${partIndex}].rol_evento`, e.target.value, index)
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Box>
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('eventos_cientificos')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label="Eventos Científicos JSON"
          value={jsonInput}
          onChange={handleJsonInput}
          placeholder="Ingresa el JSON aquí"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleProcessJson}>
          Procesar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Eventos Científicos
        </Button>
      </Grid>
    </>
  );
};

export default ScientificEvents;

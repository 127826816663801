import React, { useState } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

const BibliographicProduction = ({ formData, handleChange, handleAddField, handleRemoveField, handleSaveSection }) => {
  const [jsonInput, setJsonInput] = useState('');
  const data = Array.isArray(formData) ? formData : [];
  const { t } = useTranslation();
  const handleJsonInput = (event) => {
    setJsonInput(event.target.value);
  };

  const handleProcessJson = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (Array.isArray(parsedData)) {
        parsedData.forEach((production, index) => {
          const newIndex = data.length + index; // Calcular el índice correctamente
          handleAddField('produccion_bibliografica');
          handleChange('produccion_bibliografica', 'tipo_produccion', production.tipo_produccion || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'titulo_articulo', production.titulo_articulo || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'revista', production.revista || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'issn', production.issn || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'editorial', production.editorial || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'volumen', production.volumen || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'numero', production.numero || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'paginas', production.paginas || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'fecha', production.fecha || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'doi', production.doi || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'url_articulo', production.url_articulo || 'sin datos', newIndex);
          handleChange('produccion_bibliografica', 'url_drive', production.url_drive || 'sin datos', newIndex);
        });
        setJsonInput(''); // Clear the input after processing
      } else {
        console.error('JSON inválido. Debe ser un arreglo de objetos.');
      }
    } catch (error) {
      console.error('Invalid JSON', error);
    }
  };

  const handleSave = () => {
    const cleanedData = data.map(item => ({
      tipo_produccion: item.tipo_produccion || 'sin datos',
      titulo_articulo: item.titulo_articulo || 'sin datos',
      revista: item.revista || 'sin datos',
      issn: item.issn || 'sin datos',
      editorial: item.editorial || 'sin datos',
      volumen: item.volumen || 'sin datos',
      numero: item.numero || 'sin datos',
      paginas: item.paginas || 'sin datos',
      fecha: item.fecha || 'sin datos',
      doi: item.doi || 'sin datos',
      url_articulo: item.url_articulo || 'sin datos',
      url_drive: item.url_drive || 'sin datos',
    }));
    handleSaveSection('produccion_bibliografica', cleanedData);
  };

  const handleClearAll = () => {
    handleSaveSection('produccion_bibliografica', []); // Guardar una lista vacía para limpiar todas las entradas
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
        {t('bibliographicProduction')}
        </Typography>
        <Divider />
      </Grid>
      <Box
        sx={{
          maxHeight: '60vh', // Adjust the height as needed
          overflowY: 'auto',
          width: '100%',
        }}
      >
        {data.map((production, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tipo de Producción"
                value={production.tipo_produccion || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'tipo_produccion', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Título del Artículo"
                value={production.titulo_articulo || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'titulo_articulo', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Revista"
                value={production.revista || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'revista', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="ISSN"
                value={production.issn || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'issn', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Editorial"
                value={production.editorial || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'editoriales', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Volumen"
                value={production.volumen || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'volumen', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Número"
                value={production.numero || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'numero', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Páginas"
                value={production.paginas || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'paginas', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Fecha"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={production.fecha || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'fecha', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="DOI"
                value={production.doi || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'doi', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="URL del Artículo (debe contener .pdf o -pdf)"
                helperText="Debe ser una URL válida que contenga .pdf o -pdf"
                value={production.url_articulo || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'url_articulo', e.target.value, index)}
                error={production.url_articulo && !/(\.pdf$|pdf$)/.test(production.url_articulo)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="URL del Archivo en Google Drive"
                helperText="Debe ser una URL válida de Google Drive"
                value={production.url_drive || ''}
                onChange={(e) => handleChange('produccion_bibliografica', 'url_drive', e.target.value, index)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton onClick={() => handleRemoveField('produccion_bibliografica', index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('produccion_bibliografica')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label="Producción Bibliográfica JSON"
          value={jsonInput}
          onChange={handleJsonInput}
          placeholder='Ingresa el JSON aquí'
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleProcessJson}>
          Procesar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Producción Bibliográfica
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClearAll}>
          Borrar Toda la Producción Bibliográfica
        </Button>
      </Grid>
    </>
  );
};

export default BibliographicProduction;

import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';

const ExternalInternsForm = ({ formData = {}, handleSaveSection }) => {
  const [nacionales, setNacionales] = useState(0);
  const [internacionales, setInternacionales] = useState(0);

  useEffect(() => {
    if (formData?.nacionales) {
      setNacionales(formData.nacionales);
    }
    if (formData?.internacionales) {
      setInternacionales(formData.internacionales);
    }
  }, [formData]);

  const handleSave = () => {
    handleSaveSection('pasantes_externos', { nacionales, internacionales });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Pasantes Externos
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Nacionales"
          type="number"
          value={nacionales}
          onChange={(e) => setNacionales(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Internacionales"
          type="number"
          value={internacionales}
          onChange={(e) => setInternacionales(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Guardar Pasantes
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExternalInternsForm;

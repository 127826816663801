import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Box, IconButton, Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.webp'; // Asegúrate de tener un logo en la carpeta `assets'
import { logout } from '../store';
import { useTranslation } from 'react-i18next';

const MenuItem = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(3),
  cursor: 'pointer',
  color: '#FFFFFF',
  textDecoration: 'none',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 'bold',
  fontSize: '1rem',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '0%',
    height: '2px',
    bottom: '-4px',
    left: '50%',
    backgroundColor: '#402E7A',
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover': {
    color: '#402E7A',
    '&::after': {
      width: '100%',
      left: '0%',
    },
  },
}));

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#3DC2EC', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box component="img" sx={{ height: 90 }} alt="Logo" src={logo} />
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: '20px' }}>
          <MenuItem to="/">{t('home')}</MenuItem>
          <MenuItem to="/about">{t('about')}</MenuItem>
          <MenuItem to="/services">{t('services')}</MenuItem>
          <MenuItem to="/contact">{t('contact')}</MenuItem>
          {!user && <MenuItem to="/login">{t('login')}</MenuItem>}
          {user && (
            <>
              <Button component={Link} to="/add-researcher" variant="contained" color="primary">
                {t('addResearcher')}
              </Button>
              <Button onClick={handleLogout} variant="contained" color="secondary">
                {t('logout')}
              </Button>
            </>
          )}
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'block', md: 'none' } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            <List>
              {['home', 'about', 'services', 'contact'].map((text) => (
                <ListItem button key={text} component={Link} to={`/${text.toLowerCase()}`}>
                  <ListItemText primary={t(text)} />
                </ListItem>
              ))}
              {!user && (
                <ListItem button component={Link} to="/login">
                  <ListItemText primary={t('login')} />
                </ListItem>
              )}
              {user && (
                <>
                  <ListItem button component={Link} to="/add-researcher">
                    <ListItemText primary={t('addResearcher')} />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary={t('logout')} />
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { BehaviorSubject } from 'rxjs';

class ResearcherService {
    constructor() {
        // Inicializamos el BehaviorSubject con un valor inicial de null
        this.researcherSubject = new BehaviorSubject(null);
    }

    // Método para actualizar el investigador seleccionado
    setResearcher(researcher) {
        this.researcherSubject.next(researcher);
    }

    // Método para obtener el observable del investigador
    getResearcherObservable() {
        return this.researcherSubject.asObservable();
    }

    // Método para obtener los datos actuales del investigador
    getCurrentResearcher() {
        return this.researcherSubject.getValue();
    }

    // Método para obtener la producción bibliográfica
    getProduccionBibliografica() {
        const researcher = this.getCurrentResearcher();
        return researcher?.produccion_bibliografica || [];
    }

    // Método para obtener los eventos científicos
    getScientificEvents() {
        const researcher = this.getCurrentResearcher();
        return researcher?.eventos_cientificos || [];
    }

    // Método para obtener los reconocimientos
    getRecognitions() {
        const researcher = this.getCurrentResearcher();
        return researcher?.reconocimientos || [];
    }

    // Método para obtener las líneas de investigación
    getResearchLines() {
        const researcher = this.getCurrentResearcher();
        return researcher?.lineas_investigacion || [];
    }

    // Método para obtener los pasantes externos
    getPasantesExternos() {
        const researcher = this.getCurrentResearcher();
        return researcher?.pasantes_externos || { nacionales: 0, internacionales: 0 };
    }

    // Método para obtener las alianzas y cooperación
    getAlliancesCooperation() {
        const researcher = this.getCurrentResearcher();
        return researcher?.alianzas_cooperacion || { nacionales: [], internacionales: [] };
    }

    // Método para obtener la oferta de productos y servicios
    getProductServiceOffer() {
        const researcher = this.getCurrentResearcher();
        return researcher?.oferta_productos_servicios || { titulo: '', texto: '' };
    }
    
    // Método para obtener la biografía
    getBiography() {
        const researcher = this.getCurrentResearcher();
        return researcher?.biografia || { texto: '' };
    }

    // Método para obtener los próximos eventos
    getUpcomingEvents() {
        const researcher = this.getCurrentResearcher();
        console.log('Proximos eventos:', researcher?.proximos_eventos); // Verificar los datos
        return researcher?.proximos_eventos || [];
    }
    
}

export const researcherService = new ResearcherService();

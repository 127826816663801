import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Divider, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const SocialNetworks = ({ formData, handleChange, handleSaveSection }) => {
  const data = formData || {};
  const [extraFields, setExtraFields] = useState(data.extraFields || []);

  const handleAddField = () => {
    setExtraFields([...extraFields, { id: Date.now(), value: '' }]);
  };

  const handleExtraFieldChange = (index, newValue) => {
    const updatedFields = extraFields.map((field, i) =>
      i === index ? { ...field, value: newValue } : field
    );
    setExtraFields(updatedFields);
  };

  useEffect(() => {
    handleChange('redes_sociales_academicas', 'extraFields', extraFields);
  }, [extraFields, handleChange]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Redes Sociales Académicas
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Google Scholar"
          value={data.google_scholar || ''}
          onChange={(e) => handleChange('redes_sociales_academicas', 'google_scholar', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="ResearchGate"
          value={data.researchgate || ''}
          onChange={(e) => handleChange('redes_sociales_academicas', 'researchgate', e.target.value)}
        />
      </Grid>
      {extraFields.map((field, index) => (
        <Grid item xs={12} md={6} key={field.id}>
          <TextField
            fullWidth
            label={`Extra Field ${index + 1}`}
            value={field.value}
            onChange={(e) => handleExtraFieldChange(index, e.target.value)}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={handleAddField}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('redes_sociales_academicas', { ...data, extraFields })}>
          Guardar Redes Sociales Académicas
        </Button>
      </Grid>
    </>
  );
};

export default SocialNetworks;

import React, { useState } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ProfessionalExperience = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const [jsonText, setJsonText] = useState('');
  const data = Array.isArray(formData) ? formData : [];

  const handleJsonChange = (e) => {
    setJsonText(e.target.value);
  };

  const handleJsonLoad = () => {
    try {
      const parsedData = JSON.parse(jsonText);
      if (Array.isArray(parsedData)) {
        parsedData.forEach((experience, index) => {
          if (!data[index]) {
            handleAddField('experiencia_profesional');
          }
          handleChange('experiencia_profesional', 'institucion', experience.institucion || 'sin datos', index);
          handleChange('experiencia_profesional', 'dedicacion', experience.dedicacion || 'sin datos', index);
          handleChange('experiencia_profesional', 'fecha_inicio', experience.fecha_inicio || 'sin datos', index);
          handleChange('experiencia_profesional', 'fecha_fin', experience.fecha_fin || 'sin datos', index);
        });
      } else {
        console.error('JSON debe ser un array.');
      }
    } catch (error) {
      console.error('Invalid JSON', error);
    }
  };

  const handleSave = () => {
    const cleanedData = data.map(item => ({
      institucion: item.institucion || 'sin datos',
      dedicacion: item.dedicacion || 'sin datos',
      fecha_inicio: item.fecha_inicio || 'sin datos',
      fecha_fin: item.fecha_fin || 'sin datos'
    }));
    handleSaveSection('experiencia_profesional', cleanedData);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Experiencia Profesional
        </Typography>
        <Divider />
      </Grid>
      {data.map((experience, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Institución"
              value={experience.institucion || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'institucion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Dedicación (Horas Semanales)"
              value={experience.dedicacion || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'dedicacion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Inicio"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={experience.fecha_inicio || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'fecha_inicio', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Fin"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={experience.fecha_fin || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'fecha_fin', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('experiencia_profesional')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="JSON de Experiencia Profesional"
          multiline
          rows={4}
          value={jsonText}
          onChange={handleJsonChange}
        />
        <Button variant="contained" color="primary" onClick={handleJsonLoad}>
          Cargar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Experiencia Profesional
        </Button>
      </Grid>
    </>
  );
};

export default ProfessionalExperience;

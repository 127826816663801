import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';

const BiographyForm = ({ formData, handleSaveSection }) => {
  // Estado local para manejar el texto de la biografía
  const [biografiaTexto, setBiografiaTexto] = useState('');

  // Efecto para sincronizar el estado local con el estado global
  useEffect(() => {
    if (formData.biografia?.texto) {
      setBiografiaTexto(formData.biografia.texto);
    }
  }, [formData]);

  // Función para manejar cambios en el texto de la biografía
  const handleInputChange = (e) => {
    setBiografiaTexto(e.target.value);
  };

  // Función para guardar la biografía
  const handleSave = () => {
    handleSaveSection('biografia', { texto: biografiaTexto });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Biografía del Investigador
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Biografía"
          multiline
          rows={6}
          value={biografiaTexto}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Guardar Biografía
        </Button>
      </Grid>
    </Grid>
  );
};

export default BiographyForm;

import React, { useState } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AcademicFormation = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const [jsonText, setJsonText] = useState('');
  const [data, setData] = useState(Array.isArray(formData) ? formData : []);

  const handleJsonChange = (e) => {
    setJsonText(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date)) {
      return date.toISOString().split('T')[0]; // Retorna la fecha en formato YYYY-MM-DD
    }
    return '';
  };

  const handleJsonLoad = () => {
    try {
      const parsedData = JSON.parse(jsonText);
      if (Array.isArray(parsedData)) {
        const updatedData = parsedData.map((academic) => ({
          nivel_academico: academic.nivel_academico || 'sin datos',
          institucion: academic.institucion || 'sin datos',
          programa: academic.programa || 'sin datos',
          fecha_inicio: formatDate(academic.fecha_inicio) || '',
          fecha_fin: formatDate(academic.fecha_fin) || '',
          lugar: academic.lugar || 'sin datos',
        }));

        setData(updatedData);
      } else {
        console.error('El JSON debe ser un array.');
      }
    } catch (error) {
      console.error('JSON inválido', error);
    }
  };

  const handleSave = () => {
    handleSaveSection('formacion_academica', data);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Formación Académica
        </Typography>
        <Divider />
      </Grid>
      {data.map((academic, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nivel Académico"
              value={academic.nivel_academico || ''}
              onChange={(e) => handleChange('formacion_academica', 'nivel_academico', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Institución"
              value={academic.institucion || ''}
              onChange={(e) => handleChange('formacion_academica', 'institucion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Programa"
              value={academic.programa || ''}
              onChange={(e) => handleChange('formacion_academica', 'programa', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Inicio"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={academic.fecha_inicio || ''}
              onChange={(e) => handleChange('formacion_academica', 'fecha_inicio', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Fin"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={academic.fecha_fin || ''}
              onChange={(e) => handleChange('formacion_academica', 'fecha_fin', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Lugar"
              value={academic.lugar || ''}
              onChange={(e) => handleChange('formacion_academica', 'lugar', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('formacion_academica')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="JSON de Formación Académica"
          multiline
          rows={4}
          value={jsonText}
          onChange={handleJsonChange}
        />
        <Button variant="contained" color="primary" onClick={handleJsonLoad}>
          Cargar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Formación Académica
        </Button>
      </Grid>
    </>
  );
};

export default AcademicFormation;

import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';

// Styled components for hover and shadow effects
const InfoBlock = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[6],
        transform: 'translateY(-4px)',
    },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
}));

const ActionAreas = ({ areas }) => {
    return (
        <InfoBlock>
            <SectionHeader variant="h6" gutterBottom>Áreas de Actuación</SectionHeader>
            <Grid container spacing={2}>
                {areas.length > 0 ? (
                    areas.map((area, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <Paper sx={{ padding: 2, boxShadow: 3, '&:hover': { boxShadow: 6 }, transition: 'box-shadow 0.3s ease-in-out' }}>
                                <Typography variant="h6">{area.linea_investigacion}</Typography>
                                <Typography variant="body2">Activa: {area.activa}</Typography>
                            </Paper>
                        </Grid>
                    ))
                ) : (
                    <Typography>No hay datos</Typography>
                )}
            </Grid>
        </InfoBlock>
    );
};

export default ActionAreas;

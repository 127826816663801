import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';

const ProductServiceOfferForm = ({ formData = {}, handleSaveSection }) => {
  // Estado local para manejar el título y el texto de la oferta
  const [titulo, setTitulo] = useState('');
  const [texto, setTexto] = useState('');

  useEffect(() => {
    if (formData?.titulo) {
      setTitulo(formData.titulo);
    }
    if (formData?.texto) {
      setTexto(formData.texto);
    }
  }, [formData]);

  const handleTituloChange = (e) => {
    setTitulo(e.target.value);
  };

  const handleTextoChange = (e) => {
    setTexto(e.target.value);
  };

  const handleSave = () => {
    handleSaveSection('oferta_productos_servicios', { titulo, texto });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Oferta de Productos y Servicios
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Título"
          value={titulo}
          onChange={handleTituloChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Texto"
          multiline
          rows={6}
          value={texto}
          onChange={handleTextoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Guardar Oferta
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductServiceOfferForm;

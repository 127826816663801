import React from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 0,
  borderRadius: 2,
};

const InfoModalGoogle = ({ open, handleClose, info }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
            {info.title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: 'justify' }}>
          {info.description}
        </Typography>
        {info.download_url && (
          <Button
            href={info.download_url}
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="primary"
            sx={{ mt: 2, display: 'block', textTransform: 'none' }}
          >
            {t('Ver artículo')}
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default InfoModalGoogle;

import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Divider, Typography, Switch, FormControlLabel } from '@mui/material';

const PersonalInformation = ({ formData, handleChange, handleFileChange, handleSaveSection }) => {
  const data = formData || {};
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (data.foto && typeof data.foto === 'string') {
      const fileNameFromPath = data.foto.split('/').pop();
      setFileName(fileNameFromPath);
    } else if (data.foto && typeof data.foto === 'object' && data.foto.name) {
      setFileName(data.foto.name);
    } else {
      setFileName('');
    }
  }, [data]);

  const onFileChange = (event) => {
    handleFileChange(event);
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
    }
  };

  const handleSave = () => {
    console.log('Form Data:', formData);
    handleSaveSection('informacion_personal', formData);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Información Personal
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nombre Completo"
          value={data.nombre_completo || ''}
          onChange={(e) => handleChange('informacion_personal', 'nombre_completo', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nombre en Citaciones"
          value={data.nombre_en_citaciones || ''}
          onChange={(e) => handleChange('informacion_personal', 'nombre_en_citaciones', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Documento de Identidad"
          value={data.documento_identidad || ''}
          onChange={(e) => handleChange('informacion_personal', 'documento_identidad', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nacionalidad"
          value={data.nacionalidad || ''}
          onChange={(e) => handleChange('informacion_personal', 'nacionalidad', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Fecha de Nacimiento"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.fecha_nacimiento || ''}
          onChange={(e) => handleChange('informacion_personal', 'fecha_nacimiento', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Lugar de Nacimiento"
          value={data.lugar_nacimiento || ''}
          onChange={(e) => handleChange('informacion_personal', 'lugar_nacimiento', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Sexo"
          value={data.sexo || ''}
          onChange={(e) => handleChange('informacion_personal', 'sexo', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center">
        <Button variant="contained" component="label">
          Subir Foto
          <input type="file" hidden onChange={onFileChange} />
        </Button>
        {fileName && (
          <Typography variant="body2" style={{ marginLeft: '10px' }}>
            {fileName}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={
            <Switch
              checked={data.visual !== undefined ? data.visual : true} // Por defecto en true si no está definido
              onChange={(e) => handleChange('informacion_personal', 'visual', e.target.checked)}
              color="primary"
            />
          }
          label="Mostrar Información"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {data.id ? 'Actualizar Información Personal' : 'Guardar Información Personal'}
        </Button>
      </Grid>
    </>
  );
};

export default PersonalInformation;

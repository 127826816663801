import React, { useState } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ClinicalStudies = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const [jsonText, setJsonText] = useState('');
  const data = Array.isArray(formData) ? formData : [];

  const handleJsonChange = (e) => {
    setJsonText(e.target.value);
  };

  const handleJsonLoad = () => {
    try {
      const parsedData = JSON.parse(jsonText);
      if (Array.isArray(parsedData)) {
        parsedData.forEach((study, index) => {
          if (!data[index]) {
            handleAddField('clinical_studies');
          }
          handleChange('clinical_studies', 'title', study.title || 'sin datos', index);
          handleChange('clinical_studies', 'initial_description', study.initial_description || 'sin datos', index);
          handleChange('clinical_studies', 'url', study.url || 'sin datos', index);
        });
      } else {
        console.error('El JSON debe ser un array.');
      }
    } catch (error) {
      console.error('JSON inválido', error);
    }
  };

  const handleSave = () => {
    const cleanedData = data.map(item => ({
      title: item.title || 'sin datos',
      initial_description: item.initial_description || 'sin datos',
      url: item.url || 'sin datos'
    }));
    handleSaveSection('clinical_studies', cleanedData);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Estudios Clínicos
        </Typography>
        <Divider />
      </Grid>
      {data.map((study, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título"
              value={study.title || ''}
              onChange={(e) => handleChange('clinical_studies', 'title', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Descripción Inicial"
              value={study.initial_description || ''}
              onChange={(e) => handleChange('clinical_studies', 'initial_description', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="URL"
              value={study.url || ''}
              onChange={(e) => handleChange('clinical_studies', 'url', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('clinical_studies')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="JSON de Estudios Clínicos"
          multiline
          rows={4}
          value={jsonText}
          onChange={handleJsonChange}
        />
        <Button variant="contained" color="primary" onClick={handleJsonLoad}>
          Cargar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Estudios Clínicos
        </Button>
      </Grid>
    </>
  );
};

export default ClinicalStudies;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { researcherService } from '../services/researcherServices';
import { useTranslation } from 'react-i18next'; 

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
    textTransform: 'capitalize',
    fontWeight: 'bold',
}));

const EventItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[1],
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[3],
        transform: 'translateY(-2px)',
    },
}));

const ProximosEventos = () => {
    const theme = useTheme();
    const [eventos, setEventos] = useState([]);
    const { t } = useTranslation(); 

    useEffect(() => {
        // Suscribirse al observable para recibir actualizaciones cuando cambie el investigador
        const subscription = researcherService.getResearcherObservable().subscribe((researcher) => {
            if (researcher) {
                const upcomingEvents = researcherService.getUpcomingEvents();

                if (Array.isArray(upcomingEvents)) {
                    setEventos(upcomingEvents);
                } else if (upcomingEvents && typeof upcomingEvents === 'object') {
                    setEventos([upcomingEvents]);
                } else {
                    setEventos([]);
                }
            }
        });

        // Limpiar la suscripción al desmontar el componente
        return () => subscription.unsubscribe();
    }, []);

    return (
        <Box>
            <SectionHeader variant="h6">{t('upcomingEvents')}</SectionHeader>
            {eventos.length > 0 ? (
                eventos.map((evento, index) => (
                    <EventItem key={index}>
                        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                            {evento.nombreEvento}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Fecha:</strong> {evento.fechaEvento}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Hora:</strong> {evento.horaEvento}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Lugar:</strong> {evento.lugarEvento}
                        </Typography>
                    </EventItem>
                ))
            ) : (
                <Typography variant="body1">No hay eventos próximos disponibles.</Typography>
            )}
        </Box>
    );
};

export default ProximosEventos;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const BackgroundBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '150px',
  background: `linear-gradient(120deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%), url('https://picsum.photos/1920/300?blur=2')`,
  backgroundSize: 'cover',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
}));

const formatTitle = (title) => {
  return title
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const Banner = ({ title }) => {
  return (
    <BackgroundBox>
      <Typography variant="h4" sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontWeight: 'bold' }}>
        {title ? formatTitle(title) : 'Banner Estático'}
      </Typography>
    </BackgroundBox>
  );
};

export default Banner;

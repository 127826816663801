import React, { useState } from 'react';
import { Box, Typography, TextField, Paper, Grid, Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
    fontWeight: 'bold',
    textAlign: 'center',
}));

const TrialItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#f9f9f9',
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[2],
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[4],
        transform: 'translateY(-2px)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '250px',
}));

const ClinicalTrials = ({ trials = [] }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleOpenPopup = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer,width=800,height=600');
    };

    const filteredTrials = trials.filter(trial => 
        trial.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ padding: 2 }}>
            <SectionHeader variant="h5">
                {t('clinicalTrials')}
            </SectionHeader>
            <TextField
                label={t('search')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <Grid container spacing={2}>
                {filteredTrials.length > 0 ? (
                    filteredTrials.map((trial, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <TrialItem>
                                <Box>
                                    <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                                        {trial.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {trial.initial_description}
                                    </Typography>
                                </Box>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    sx={{ marginTop: 1 }} 
                                    onClick={() => handleOpenPopup(trial.url)}
                                >
                                    Ver Caso de Estudio
                                </Button>
                            </TrialItem>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="body1">{t('noData')}</Typography>
                )}
            </Grid>
        </Box>
    );
};


export default ClinicalTrials;

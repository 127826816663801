import React, { useState } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Recognitions = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const [jsonText, setJsonText] = useState('');
  const data = Array.isArray(formData) ? formData : [];

  const handleJsonChange = (e) => {
    setJsonText(e.target.value);
  };

  const handleJsonLoad = () => {
    try {
      const parsedData = JSON.parse(jsonText);
      if (Array.isArray(parsedData)) {
        parsedData.forEach((recognition, index) => {
          if (!data[index]) {
            handleAddField('reconocimientos');
          }
          handleChange('reconocimientos', 'nombre_reconocimiento', recognition.nombre_reconocimiento || 'sin datos', index);
          handleChange('reconocimientos', 'fecha', recognition.fecha || 'sin datos', index);
        });
      } else {
        console.error('JSON debe ser un array.');
      }
    } catch (error) {
      console.error('Invalid JSON', error);
    }
  };

  const handleSave = () => {
    const cleanedData = data.map(item => ({
      nombre_reconocimiento: item.nombre_reconocimiento || 'sin datos',
      fecha: item.fecha || 'sin datos'
    }));
    handleSaveSection('reconocimientos', cleanedData);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Reconocimientos
        </Typography>
        <Divider />
      </Grid>
      {data.map((recognition, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nombre del Reconocimiento"
              value={recognition.nombre_reconocimiento || ''}
              onChange={(e) => handleChange('reconocimientos', 'nombre_reconocimiento', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={recognition.fecha || ''}
              onChange={(e) => handleChange('reconocimientos', 'fecha', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('reconocimientos')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="JSON de Reconocimientos"
          multiline
          rows={4}
          value={jsonText}
          onChange={handleJsonChange}
        />
        <Button variant="contained" color="primary" onClick={handleJsonLoad}>
          Cargar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Reconocimientos
        </Button>
      </Grid>
    </>
  );
};

export default Recognitions;

import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  color: 'white',
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[1],
  textAlign: 'center',
  textTransform: 'capitalize',
  fontWeight: 'bold',
}));

const ResearchLines = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = Array.isArray(formData) ? formData : [];

  const handleSave = () => {
    const cleanedData = data.map(item => ({
      linea_investigacion: item.linea_investigacion || 'sin datos',
      activa: item.activa || 'No'
    }));
    handleSaveSection('lineas_investigacion', cleanedData);
  };

  return (
    <>
      <StyledGrid item xs={12}>
        <SectionHeader variant="h5">
          Líneas de Investigación
        </SectionHeader>
        <Divider sx={{ marginBottom: 2 }} />
      </StyledGrid>
      {data.map((line, index) => (
        <React.Fragment key={index}>
          <StyledGrid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Línea de Investigación"
              value={line.linea_investigacion || ''}
              onChange={(e) => handleChange('lineas_investigacion', 'linea_investigacion', e.target.value, index)}
              variant="outlined"
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Activa</InputLabel>
              <Select
                value={line.activa || 'No'}
                onChange={(e) => handleChange('lineas_investigacion', 'activa', e.target.value, index)}
                label="Activa"
              >
                <MenuItem value="Sí">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </StyledGrid>
        </React.Fragment>
      ))}
      <StyledGrid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledIconButton onClick={() => handleAddField('lineas_investigacion')}>
          <AddIcon />
        </StyledIconButton>
      </StyledGrid>
      <StyledGrid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Líneas de Investigación
        </Button>
      </StyledGrid>
    </>
  );
};

export default ResearchLines;

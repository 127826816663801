import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import TopHeader from './shared/TopHeader';
import Header from './shared/Header';
import Footer from './shared/Footer';
import NavigationComponent from './NavigationComponent';
import RoutesComponent from './RoutesComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'; // Asegúrate de importar tu archivo CSS
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <TopHeader />
          <Header />
          <NavigationComponent>
            <main>
              <RoutesComponent />
            </main>
          </NavigationComponent>
          <Footer />
          <ToastContainer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;

import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { styled } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

// Styled components for recognition items
const RecognitionItem = styled(Card)(({ theme }) => ({
  backgroundColor: '#ffffff',
  marginBottom: theme.spacing(1.5), // Reducido para compactar las tarjetas
  padding: theme.spacing(1.5), // Reducido para diseño más compacto
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  '&:hover': {
    boxShadow: theme.shadows[4], // Ajustado para no ser tan pronunciado
    transform: 'translateY(-2px)',
  },
  transition: 'all 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  minHeight: '130px', // Reducido para un diseño más compacto
}));

const TitleBox = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  textTransform: 'capitalize',
  fontWeight: 'bold',
}));

const formatTitle = (title) => {
  return title.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const Recognitions = ({ recognitions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRecognitions = recognitions
    .filter((recognition) =>
      recognition.nombre_reconocimiento.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => new Date(b.fecha) - new Date(a.fecha)); // Ordenar por fecha, más reciente primero

  return (
    <Box className="page-content" sx={{ padding: 1.5 }}> {/* Reducido el padding general */}
      <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 0.8, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' }, transition: 'background-color 0.3s ease-in-out' }}>
        {t('recognitions')}
      </Typography>
      <Box sx={{ marginTop: 1.5, marginBottom: 1.5 }}> {/* Reducido el margen superior e inferior */}
        <TextField
          fullWidth
          label="Buscar por Nombre del Reconocimiento"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      {filteredRecognitions.length > 0 ? (
        <Grid container spacing={1.5}> {/* Reducido el espacio entre grid items */}
          {filteredRecognitions.map((recognition, index) => (
            <Grid item xs={12} md={6} key={index}>
              <RecognitionItem>
                <CardContent>
                  <TitleBox variant="h6">
                    <StarIcon sx={{ marginRight: 1 }} />
                    {formatTitle(recognition.nombre_reconocimiento)}
                  </TitleBox>
                  <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                    <strong>Fecha:</strong> {recognition.fecha}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', padding: '8px 16px' }}> {/* Reducido el padding */}
                  <Button size="small" color="primary">
                    Ver más
                  </Button>
                </CardActions>
              </RecognitionItem>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">No hay datos</Typography>
      )}
    </Box>
  );
};

export default Recognitions;

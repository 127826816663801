import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Flag from 'react-world-flags';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const InfoBlock = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[3],
        transform: 'translateY(-2px)',
    },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
    fontWeight: 'bold',
}));

const getCountryCode = (language) => {
    const normalizedLanguage = language.toLowerCase().trim();
    switch (normalizedLanguage) {
        case 'español':
        case 'spanish':
            return 'ES';
        case 'inglés':
        case 'ingles':
        case 'english':
            return 'GB';
        case 'francés':
        case 'frances':
        case 'french':
            return 'FR';
        case 'alemán':
        case 'aleman':
        case 'german':
            return 'DE';
        default:
            return ''; // Return an empty string if the language doesn't match any case
    }
};

const Languages = ({ languages }) => {
    const theme = useTheme();
    return (
        <InfoBlock theme={theme}>
            <SectionHeader theme={theme} variant="h6" gutterBottom>Idiomas</SectionHeader>
            <Grid container spacing={2}>
                {languages.length > 0 ? (
                    languages.map((language, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    padding: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    boxShadow: 0,
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderRadius: theme.shape.borderRadius,
                                }}
                            >
                                {getCountryCode(language.idioma) && (
                                    <Flag
                                        code={getCountryCode(language.idioma)}
                                        style={{ width: 40, height: 25, marginRight: 8 }}
                                    />
                                )}
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                                    >
                                        {language.idioma}
                                    </Typography>
                                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                                        <Typography variant="body2" sx={{ marginRight: 2 }}>
                                            <strong>Habla:</strong> {language.habla}
                                        </Typography>
                                        <Typography variant="body2" sx={{ marginRight: 2 }}>
                                            <strong>Escribe:</strong> {language.escribe}
                                        </Typography>
                                        <Typography variant="body2" sx={{ marginRight: 2 }}>
                                            <strong>Lee:</strong> {language.lee}
                                        </Typography>
                                        <Typography variant="body2" sx={{ marginRight: 2 }}>
                                            <strong>Entiende:</strong> {language.entiende}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))
                ) : (
                    <Typography>No hay datos</Typography>
                )}
            </Grid>
        </InfoBlock>
    );
};

export default Languages;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, IconButton, Avatar, keyframes } from '@mui/material';
import { styled } from '@mui/system';
import { FaGoogle, FaResearchgate, FaOrcid, FaLinkedin, FaGlobe } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const levelColors = {
  'Investigador Senior': '#f7f3e9',
  'Investigador Asociado': '#e6f5e9',
  'Sub Investigador': '#ffe8d6',
};

const StyledCard = styled(Card)(({ theme, level }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  backgroundColor: levelColors[level] || '#f5f5f0',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  cursor: 'pointer',
  animation: `${fadeIn} 0.5s ease-out`,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.5),
  },
}));

const StyledCardMediaContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '16px 0',
  height: 160,
});

const StyledAvatar = styled(Avatar)({
  width: 140,
  height: 140,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

const SocialIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
}));

const ResearcherCard = ({ id, informacion_personal, nivel, redes_sociales_academicas, identificadores_de_autor, resumen, biografia, visual }) => {
  const navigate = useNavigate();
  const placeholderImage = 'https://picsum.photos/140';
  const [expanded, setExpanded] = useState(false);

  // Verificar el valor de visual
  console.log('Visual:', visual);

  if (visual === false) {
    return null;
  }

  const handleCardClick = () => {
    navigate(`/researcher/${id}`);
  };

  const formatName = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderSocialIcon = (platform) => {
    switch (platform) {
      case 'google_scholar':
        return <FaGoogle />;
      case 'researchgate':
        return <FaResearchgate />;
      case 'orcid':
        return <FaOrcid />;
      case 'linkedin':
        return <FaLinkedin />;
      default:
        return <FaGlobe />;
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard onClick={handleCardClick} level={nivel}>
      <StyledCardMediaContainer>
        <StyledAvatar
          src={informacion_personal?.foto || placeholderImage}
          alt={informacion_personal?.nombre_completo || 'Placeholder Name'}
        />
      </StyledCardMediaContainer>
      <CardContent sx={{ flexGrow: 1 }}>
        {informacion_personal?.nombre_completo && (
          <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Poppins, Arial', textAlign: 'center' }}>
            {formatName(informacion_personal.nombre_completo)}
          </Typography>
        )}
        {nivel && (
          <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Poppins, Arial', textAlign: 'center' }}>
            {nivel}
          </Typography>
        )}
        {biografia && (
          <Typography variant="body2" color="text.secondary" sx={{ marginTop: '10px', fontFamily: 'Poppins, Arial', textAlign: 'justify' }}>
            {expanded ? biografia : `${biografia.substring(0, 150)}...`}
            <Typography
              variant="body2"
              color="primary"
              onClick={(e) => {
                e.stopPropagation(); // Evita que se dispare el evento onClick del card
                handleExpandClick();
              }}
              sx={{ cursor: 'pointer', display: 'inline-block', marginLeft: '5px' }}
            >
              {expanded ? 'Leer menos' : 'Leer más'}
            </Typography>
          </Typography>
        )}
      </CardContent>
      <SocialIcons>
        {redes_sociales_academicas && Object.entries(redes_sociales_academicas).map(([platform, value]) => (
          platform !== "extraFields" && (
            <IconButton key={platform} aria-label={platform} href={value} target="_blank" sx={{ color: '#FFFFFF' }}>
              {renderSocialIcon(platform)}
            </IconButton>
          )
        ))}
        {redes_sociales_academicas?.extraFields && redes_sociales_academicas.extraFields.map((field) => (
          <IconButton key={field.id} aria-label="Extra" href={field.value} target="_blank" sx={{ color: '#FFFFFF' }}>
            <FaGlobe />
          </IconButton>
        ))}
        {identificadores_de_autor && Object.entries(identificadores_de_autor).map(([platform, value]) => (
          platform !== "extraFields" && (
            <IconButton key={platform} aria-label={platform} href={value} target="_blank" sx={{ color: '#FFFFFF' }}>
              {renderSocialIcon(platform)}
            </IconButton>
          )
        ))}
        {identificadores_de_autor?.extraFields && identificadores_de_autor.extraFields.map((field) => (
          <IconButton key={field.id} aria-label="Extra" href={field.value} target="_blank" sx={{ color: '#FFFFFF' }}>
            <FaGlobe />
          </IconButton>
        ))}
      </SocialIcons>
    </StyledCard>
  );
};

export default ResearcherCard;

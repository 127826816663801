import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Detecta automáticamente el idioma del navegador
  .use(initReactI18next) // Integración con React
  .init({
    resources: {
      en: {
        translation: {
          "home": "Home",
          "about": "About",
          "services": "Services",
          "contact": "Contact",
          "login": "Login",
          "addResearcher": "Add Researcher",
          "logout": "Logout",
          "meetOurResearchers": "Meet Our Researchers",
          "unknownTitle": "Unknown Title",
          "noSummaryAvailable": "No Summary Available",
          "noData": "No Data",
          "footerText": "© 2024 Clinical Research Center of the Coast. Designed by: Leidy Vega Anaya & Fabian Muñoz Puello. All rights reserved.",
          "email": "Email",
          "signIn": "Sign in",
          "emailAddress": "Email Address",
          "password": "Password",
          "signingIn": "Signing In...",
          "bibliographicProduction": "Bibliographic Production",
          "typeOfProduction": "Type of Production",
          "articleTitle": "Article Title",
          "journal": "Journal",
          "issn": "ISSN",
          "publisher": "Publisher",
          "volume": "Volume",
          "number": "Number",
          "pages": "Pages",
          "date": "Date",
          "doi": "DOI",
          "articleUrl": "Article URL (must contain .pdf or -pdf)",
          "driveUrl": "Google Drive URL",
          "jsonPlaceholder": "Enter the JSON here",
          "processJson": "Process JSON",
          "saveBibliographicProduction": "Save Bibliographic Production",
          "clearAllBibliographicProduction": "Clear All Bibliographic Production",
          "scientificEvents": "Scientific Events",
          "searchByEventName": "Search by Event Name",
          "eventType": "Event Type",
          "scope": "Scope",
          "startDate": "Start Date",
          "endDate": "End Date",
          "location": "Location",
          "associatedInstitutions": "Associated Institutions",
          "participants": "Participants",
          "directedWorkTutorials": "Directed Work/Tutorials",
          "searchByTitle": "Search by Title",
          "institution": "Institution",
          "status": "Status",
          "specialty": "Specialty",
          "role": "Role",
          "oriented": "Oriented",
          "tutors": "Tutors",
          "generalInfo": "General Information",
          "professionalExperience": "Professional Experience",
          "researchLines": "Research Lines",
          "recognitions": "Recognitions",
          "supervisedWorks": "Supervised Works",
          "personalInfo": "Personal Information",
          "documentId": "Document ID",
          "citationName": "Citation Name",
          "sex": "Sex",
          "nationality": "Nationality",
          "birthDate": "Birth Date",
          "birthPlace": "Birth Place",
          "academicSocialNetworks": "Academic Social Networks",
          "authorIdentifiers": "Author Identifiers",
          "authorIdScopus": "Author ID (Scopus)",
          "professionalAddress": "Professional Address",
          "professionalNeighborhood": "Professional Neighborhood",
          "professionalPhone": "Professional Phone",
          "institutionalEmail": "Institutional Email",
          "residentialAddress": "Residential Address",
          "residentialNeighborhood": "Residential Neighborhood",
          "municipality": "Municipality",
          "residentialPhone": "Residential Phone",
          "personalEmail": "Personal Email",
          "researcher": "Researcher",
          "biography": "Biography",
          "scholarArticles": "Scholar Articles", // Nueva traducción
          "totalCitations": "Total Citations",
          "description": "Description",
          "clinicalTrials": "Clinical Trials",
          "search": "Search",
          "viewStudyCase": "View Study Case",
          "searchByRecognitionName": "search Recognition Name",
          "academicSocialNetworksAndIdentifiers": "Academic Social Networks",
          "researchProductsAndServices": "Research Products and Services (Last 5 Years)",
          "upcomingEvents": "Upcoming Events",
"productServiceOffer": "Product and service offer (last 5 years)",
"otherResearchers": "Other Researchers",
          "externalInterns": "External Interns (Last 5 Years)",
          "alliancesAndCooperation": "Alliances and Cooperation (Last 5 Years)",
          "languages": "Languages",
          "national": "National",
          "international": "International"

        }
      },
      es: {
        translation: {
          "home": "Inicio",
          "about": "Acerca de",
          "services": "Servicios",
          "contact": "Contacto",
          "login": "Iniciar sesión",
          "addResearcher": "Agregar Investigador",
          "logout": "Cerrar sesión",
          "meetOurResearchers": "Conoce a nuestros investigadores",
          "unknownTitle": "Título Desconocido",
          "noSummaryAvailable": "Resumen no disponible",
          "noData": "No hay datos",
          "footerText": "© 2024 Centro de Investigación Clínica de la Costa. Diseñado por: Leidy Vega Anaya & Fabian Muñoz Puello. Todos los derechos reservados.",
          "email": "Correo",
          "signIn": "Iniciar sesión",
          "emailAddress": "Correo Electrónico",
          "password": "Contraseña",
          "signingIn": "Iniciando sesión...",
          "bibliographicProduction": "Producción Bibliográfica",
          "typeOfProduction": "Tipo de Producción",
          "articleTitle": "Título del Artículo",
          "journal": "Revista",
          "issn": "ISSN",
          "publisher": "Editorial",
          "volume": "Volumen",
          "number": "Número",
          "pages": "Páginas",
          "date": "Fecha",
          "doi": "DOI",
          "articleUrl": "URL del Artículo (debe contener .pdf o -pdf)",
          "driveUrl": "URL del Archivo en Google Drive",
          "jsonPlaceholder": "Ingresa el JSON aquí",
          "processJson": "Procesar JSON",
          "saveBibliographicProduction": "Guardar Producción Bibliográfica",
          "clearAllBibliographicProduction": "Borrar Toda la Producción Bibliográfica",
          "scientificEvents": "Eventos Científicos",
          "searchByEventName": "Buscar por Nombre del Evento",
          "eventType": "Tipo de Evento",
          "scope": "Ámbito",
          "startDate": "Fecha de Inicio",
          "endDate": "Fecha de Fin",
          "location": "Lugar",
          "associatedInstitutions": "Instituciones Asociadas",
          "participants": "Participantes",
          "directedWorkTutorials": "Trabajos Dirigidos/Tutorías",
          "searchByTitle": "Buscar por Título",
          "institution": "Institución",
          "status": "Estado",
          "specialty": "Especialidad",
          "role": "Rol",
          "oriented": "Orientados",
          "work": "Experencia Profesional",
          "tutors": "Tutores",
          "generalInfo": "Información General",
          "professionalExperience": "Experiencia Profesional",
          "researchLines": "Líneas de Investigación",
          "recognitions": "Reconocimientos",
          "supervisedWorks": "Trabajos Dirigidos",
          "personalInfo": "Información Personal",
          "documentId": "Documento de identidad",
          "citationName": "Nombre en citaciones",
          "sex": "Sexo",
          "nationality": "Nacionalidad",
          "birthDate": "Fecha de nacimiento",
          "birthPlace": "Lugar de nacimiento",
          "academicSocialNetworks": "Redes Sociales Académicas",
          "authorIdentifiers": "Identificadores de Autor",
          "authorIdScopus": "Autor ID (Scopus)",
          "professionalAddress": "Dirección Profesional",
          "professionalNeighborhood": "Barrio profesional",
          "professionalPhone": "Teléfono profesional",
          "institutionalEmail": "Email institucional",
          "residentialAddress": "Dirección Residencial",
          "residentialNeighborhood": "Barrio residencial",
          "municipality": "Municipio",
          "residentialPhone": "Teléfono residencial",
          "personalEmail": "Email personal",
          "researcher": "Investigador",
          "biography": "Biografía",
          "scholarArticles": "Artículos Scholar", // Nueva traducción
          "totalCitations": "Citas Totales",
          "description": "Descripción",
          "clinicalTrials": "Ensayos Clínicos",
          "search": "Buscar",
          "viewStudyCase": "Ver Caso de Estudio",
          "academicSocialNetworksAndIdentifiers": "Redes Soaciales Academicas",
          "searchByRecognitionName": "Buscar por Nombre del Reconocimiento",
          "researchProductsAndServices": "Productos Resultado de Investigación (Últimos 5 Años)",
          "upcomingEvents": "Próximos Eventos",
          "externalInterns": "Pasantes Externos (Últimos 5 Años)",
          "alliancesAndCooperation": "Alianzas y Cooperación (Últimos 5 Años)",
          "languages": "Idiomas",
          "national": "Nacionales",
          "international": "Internacionales",
          "otherResearchers": "Otros Investigadores",

          

        }
      }
    },
    fallbackLng: 'en', // Idioma por defecto si el detectado no está soportado
    debug: true, // Desactívalo en producción
    interpolation: {
      escapeValue: false, // React ya hace el escapado por seguridad
    }
  });

export default i18n;

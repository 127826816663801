import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Grid, CircularProgress, Tab, Tabs, Avatar, Divider } from '@mui/material';
import { Helmet } from 'react-helmet-async'; // Importa Helmet
import { fetchResearchers } from '../store';
import TrabajosDirigidosTutorias from './TrabajosDirigidosTutorias';
import ProduccionBibliografica from './ProduccionBibliografica';
import EventosCientificos from './EventosCientificos';
import GeneralInfo from './GeneralInfo';
import ProfessionalExperience from './ProfessionalExperience';
import Recognitions from './Recognitions';
import ClinicalTrials from './ClinicalTrials';
import ResearcherList from './ResearcherList';
import { useTranslation } from 'react-i18next';
import { researcherService } from '../services/researcherServices';

const ResearcherDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const researchers = useSelector((state) => state.researchers.list);
    const status = useSelector((state) => state.researchers.status);
    const [tabIndex, setTabIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchResearchers());
        }
    }, [status, dispatch]);

    const researcher = researchers.find(r => r.id === id);

    useEffect(() => {
        if (researcher) {
            researcherService.setResearcher(researcher); // Envía el investigador al servicio
        }
    }, [researcher]);

    const fotoURL = researcher?.informacion_personal?.foto || '';

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    if (status === 'loading') {
        return <CircularProgress />;
    }

    if (!researcher) {
        return <Typography variant="h6">Researcher not found</Typography>;
    }

    const getTitle = (sexo) => {
        if (sexo === 'Masculino') {
            return 'Dr.';
        } else if (sexo === 'Femenino') {
            return 'Dra.';
        }
        return '';
    };

    const renderContent = () => {
        switch (tabIndex) {
            case 0:
                return <GeneralInfo researcher={researcher} />;
            case 1:
                return <ProfessionalExperience experienciaProfesional={researcher.experiencia_profesional} />;
            case 2:
                return <Recognitions recognitions={researcher.reconocimientos} />;
            case 3:
                return (
                    <TrabajosDirigidosTutorias
                        normalizedResearcher={researcher}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            case 4:
                return (
                    <EventosCientificos
                        eventos={researcher.eventos_cientificos}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            case 5:
                return (
                    <ProduccionBibliografica
                        productions={researcher.produccion_bibliografica}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            case 6:
                return (
                    <ClinicalTrials
                        trials={researcher.clinical_studies}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            default:
                return null;
        }
    };

    // Filtrar investigadores para excluir al actual
    const otherResearchers = researchers.filter(r => r.id !== id);

    return (
        <Box sx={{ padding: 2, borderRadius: 2 }}>
            <Helmet>
                <title>{`${getTitle(researcher.informacion_personal.sexo)} ${researcher.informacion_personal.nombre_completo} - Investigador`}</title>
                <meta name="description" content={researcher.biografia?.texto || 'Información sobre el investigador.'} />
                <meta property="og:title" content={`${getTitle(researcher.informacion_personal.sexo)} ${researcher.informacion_personal.nombre_completo}`} />
                <meta property="og:description" content={researcher.biografia?.texto || 'Conoce más sobre este investigador.'} />
                <meta property="og:image" content={fotoURL} />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                        <Avatar
                            src={fotoURL}
                            alt={researcher?.informacion_personal?.nombre_completo}
                            sx={{
                                width: 250,
                                height: 250,
                                margin: '0 auto 16px',
                                boxShadow: 3,
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                mt: 1,
                                backgroundColor: '#4C3BCF',
                                color: 'white',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                display: 'inline-block'
                            }}
                        >
                            {getTitle(researcher.informacion_personal.sexo)} {researcher.informacion_personal.nombre_completo}
                        </Typography>
                    </Box>
                    <Tabs
                        orientation="vertical"
                        value={tabIndex}
                        onChange={handleTabChange}
                        sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {[
                            t('generalInfo'),
                            t('professionalExperience'),
                            t('recognitions'),
                            t('supervisedWorks'),
                            t('scientificEvents'),
                            t('bibliographicProduction'),
                            t('clinicalTrials')
                        ].map((label, index) => (
                            <Tab
                                key={index}
                                label={label}
                                sx={{
                                    alignItems: 'flex-start',
                                    padding: '8px 16px',
                                    '&:hover': { backgroundColor: '#f0f0f0' },
                                    ...(tabIndex === index && { backgroundColor: '#d0d0d0', fontWeight: 'bold' })
                                }}
                            />
                        ))}
                    </Tabs>
                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    <Typography variant="h6" sx={{ marginBottom: 2, display: { xs: 'none', md: 'block' }}}>
                        {t('otherResearchers')}
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <ResearcherList researchers={otherResearchers} /> {/* Ocultar en móvil */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box sx={{ position: 'relative', overflow: 'visible' }}>
                        {renderContent()}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ResearcherDetails;

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA8-IxXsAmYEYrbdrt8IH_A-KLKEn5hwkg",
  authDomain: "clinica-de-la-costa.firebaseapp.com",
  projectId: "clinica-de-la-costa",
  storageBucket: "clinica-de-la-costa.appspot.com",
  messagingSenderId: "785937294926",
  appId: "1:785937294926:web:e08a0f5308dee8f8c4051f",
  measurementId: "G-GYMV26QCCB"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Configurar persistencia de autenticación
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Persistence set to local.');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

export { db, auth, storage };

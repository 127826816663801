import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { FaHandshake, FaUserGraduate } from 'react-icons/fa';
import { researcherService } from '../services/researcherServices';
import { useTranslation } from 'react-i18next';

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[1],
    textTransform: 'capitalize',
    fontWeight: 'bold',
}));

const CategoryHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    textTransform: 'capitalize',
    fontWeight: 'bold',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
    },
}));

const CategoryBox = styled(Box)(({ theme }) => ({
    borderLeft: `3px solid ${theme.palette.grey[500]}`,
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
}));

const RedesColaboracion = () => {
    const { t } = useTranslation();
    const [pasantesExternos, setPasantesExternos] = useState({ nacionales: 0, internacionales: 0 });
    const [alianzasCooperacion, setAlianzasCooperacion] = useState({ nacionales: [], internacionales: [] });

    useEffect(() => {
        // Suscribirse al observable para recibir actualizaciones cuando cambie el investigador
        const subscription = researcherService.getResearcherObservable().subscribe((researcher) => {
            if (researcher) {
                setPasantesExternos(researcherService.getPasantesExternos());
                setAlianzasCooperacion(researcherService.getAlliancesCooperation());
            }
        });

        // Limpiar la suscripción al desmontar el componente
        return () => subscription.unsubscribe();
    }, []);

    const renderList = (items) => (
        <List>
            {items.map((item, index) => (
                <StyledListItem key={index}>
                    <ListItemText primary={item} />
                </StyledListItem>
            ))}
        </List>
    );

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <SectionHeader variant="h6">
                        <FaHandshake style={{ marginRight: '10px' }} />
                        {t('alliancesAndCooperation')}
                    </SectionHeader>
                    <CategoryBox>
                        <CategoryHeader variant="subtitle1">{t('national')}</CategoryHeader>
                        {renderList(alianzasCooperacion.nacionales)}
                        <CategoryHeader variant="subtitle1">{t('international')}</CategoryHeader>
                        {renderList(alianzasCooperacion.internacionales)}
                    </CategoryBox>
                </Grid>

                <Grid item xs={12} md={6}>
                    <SectionHeader variant="h6">
                        <FaUserGraduate style={{ marginRight: '10px' }} />
                        {t('externalInterns')}
                    </SectionHeader>
                    <CategoryBox>
                        <CategoryHeader variant="subtitle1">{t('national')}</CategoryHeader>
                        <Typography>{pasantesExternos.nacionales}</Typography>
                        <CategoryHeader variant="subtitle1">{t('international')}</CategoryHeader>
                        <Typography>{pasantesExternos.internacionales}</Typography>
                    </CategoryBox>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RedesColaboracion;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Divider, Box, TextField } from '@mui/material';
import { styled } from '@mui/system';

const StyledListItem = styled(ListItem)(({ theme, isEven }) => ({
  padding: theme.spacing(2),
  backgroundColor: isEven ? '#f9f9f9' : '#ffffff', // Alternar entre gris claro y blanco
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
}));

const ResearcherList = ({ researchers = [] }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleItemClick = (id) => {
    navigate(`/researcher/${id}`);
  };

  const formatName = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Ordenar los investigadores para que "Investigador Senior" aparezca primero
  const sortedResearchers = [...researchers].sort((a, b) => {
    const priorityLevels = ['Investigador Senior', 'Investigador Asociado', 'Investigador Junior'];
    const aLevelIndex = priorityLevels.indexOf(a.nivel) !== -1 ? priorityLevels.indexOf(a.nivel) : priorityLevels.length;
    const bLevelIndex = priorityLevels.indexOf(b.nivel) !== -1 ? priorityLevels.indexOf(b.nivel) : priorityLevels.length;

    return aLevelIndex - bLevelIndex;
  });

  // Filtrar investigadores por el término de búsqueda
  const filteredResearchers = sortedResearchers.filter((researcher) =>
    researcher.informacion_personal?.nombre_completo.toLowerCase().includes(searchTerm)
  );

  return (
    <Box sx={{ padding: 2 }}>
      {/* Buscador */}
      <TextField
        fullWidth
        label="Buscar Investigador"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ marginBottom: 2 }}
      />
      
      <Box sx={{ maxHeight: '600px', overflowY: filteredResearchers.length > 6 ? 'auto' : 'visible' }}>
        <List>
          {filteredResearchers.map((researcher, index) => (
            researcher.visual !== false && (
              <React.Fragment key={researcher.id}>
                <StyledListItem onClick={() => handleItemClick(researcher.id)} isEven={index % 2 === 0}>
                  <ListItemAvatar>
                    <Avatar
                      src={researcher.informacion_personal?.foto || 'https://via.placeholder.com/50'}
                      alt={researcher.informacion_personal?.nombre_completo || 'Placeholder'}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontFamily: 'Poppins, Arial' }}>
                        {formatName(researcher.informacion_personal.nombre_completo)}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {researcher.nivel}
                      </Typography>
                    }
                  />
                </StyledListItem>
                <Divider />
              </React.Fragment>
            )
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ResearcherList;

import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ResearcherCard from '../../components/ResearcherCard';
import { fetchResearchers } from '../../store';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const dispatch = useDispatch();
  const researchers = useSelector((state) => state.researchers.list);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchResearchers());
  }, [dispatch]);

  // Ordena los investigadores para que "Investigador Senior" aparezca primero y luego ordena alfabéticamente dentro de cada grupo
  const sortedResearchers = [...researchers].sort((a, b) => {
    const priorityLevels = ['Investigador Senior', 'Investigador Asociado', 'Investigador Junior'];
    const aLevelIndex = priorityLevels.indexOf(a.nivel) !== -1 ? priorityLevels.indexOf(a.nivel) : priorityLevels.length;
    const bLevelIndex = priorityLevels.indexOf(b.nivel) !== -1 ? priorityLevels.indexOf(b.nivel) : priorityLevels.length;

    // Verifica que 'informacion_personal' esté definida antes de acceder a sus propiedades
    const aNombreCompleto = a.informacion_personal?.nombre_completo || '';
    const bNombreCompleto = b.informacion_personal?.nombre_completo || '';

    // Si ambos investigadores tienen el mismo nivel, ordenarlos alfabéticamente por nombre
    if (aLevelIndex === bLevelIndex) {
      return aNombreCompleto.localeCompare(bNombreCompleto);
    }

    return aLevelIndex - bLevelIndex;
  });

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 } }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('meetOurResearchers')}
      </Typography>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} direction="row" alignItems="stretch">
        {sortedResearchers.map((researcher) => (
          // Condicional para renderizar solo si visual es true y si existe 'informacion_personal'
          researcher.visual && researcher.informacion_personal && (
            <Grid item xs={12} sm={6} md={4} lg={3} key={researcher.id}>
              <ResearcherCard
                id={researcher.id}
                informacion_personal={researcher.informacion_personal}
                nivel={researcher.nivel}
                redes_sociales_academicas={researcher.redes_sociales_academicas}
                identificadores_de_autor={researcher.identificadores_de_autor}
                titulo={researcher.direccion_profesional?.institucion || t('unknownTitle')}
                resumen={researcher.direccion_profesional?.email_institucional || t('noSummaryAvailable')}
                biografia={researcher.biografia?.texto || ''}
                visual={researcher.visual} // Pasamos la propiedad visual al componente
              />
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  );
};

export default Home;

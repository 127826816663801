import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Grid, Card, CardContent, Divider, IconButton, Link, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import JournalIcon from '@mui/icons-material/Book';
import ISSNIcon from '@mui/icons-material/Code';
import EditorIcon from '@mui/icons-material/Domain';
import VolumeIcon from '@mui/icons-material/LibraryBooks';
import PagesIcon from '@mui/icons-material/Pages';
import YearIcon from '@mui/icons-material/Event';
import DOIIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import InfoModalGoogle from './modalgoogle';

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.05)',
  },
  transition: 'transform 0.3s ease-in-out',
  backgroundColor: '#f9f9f9',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(1),
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  transition: 'all 0.3s ease-in-out',
  height: '48px',
  '&:hover': {
    whiteSpace: 'normal',
    overflow: 'visible',
    textOverflow: 'clip',
    backgroundColor: theme.palette.primary.dark,
    height: 'auto',
  },
}));

const DescriptionBox = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 5,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    WebkitLineClamp: 'unset',
  },
}));

const LogoImage = styled('img')({
  height: 50,
  width: 200,
  objectFit: 'contain',
  marginLeft: 10,
  verticalAlign: 'middle',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
  },
});

const ScholarArticles = ({ productions }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredArticles = productions.filter((article) =>
    article.titulo_articulo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCardClick = (article) => {
    setModalInfo(article);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box className="page-content" sx={{ padding: 2 }}>
      <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>
        {t('scholarArticles')}
      </Typography>

      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <TextField
          fullWidth
          label={t('searchByTitle')}
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ marginLeft: 2 }}>{t('loadingData')}</Typography>
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {filteredArticles.length > 0 ? (
            filteredArticles.map((article, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <StyledCard onClick={() => handleCardClick(article)}>
                  <TitleBox>
                    {article.titulo_articulo}
                  </TitleBox>
                  <Divider />
                  <CardContent sx={{ flex: '1 1 auto' }}>
                    <Typography variant="body2" color="text.secondary">
                      <JournalIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('journal')}:</strong> {article.revista || t('noData')}
                      <br />
                      <ISSNIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('issn')}:</strong> {article.issn || t('noData')}<br />
                      <EditorIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('publisher')}:</strong> {article.editorial || t('noData')}<br />
                      <VolumeIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('volume')}:</strong> {article.volumen || t('noData')}, <strong>{t('number')}:</strong> {article.numero || t('noData')}<br />
                      <PagesIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('pages')}:</strong> {article.paginas || t('noData')}<br />
                      <YearIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('date')}:</strong> {article.fecha || t('noData')}<br />
                      <DOIIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('doi')}:</strong> {article.doi || t('noData')}<br />
                      <DescriptionBox variant="body2" color="text.secondary">
                        {article.tipo_produccion}
                      </DescriptionBox>
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))
          ) : (
            <Typography variant="body1">{t('noResults')}</Typography>
          )}
        </Grid>
      )}
      <InfoModalGoogle open={modalOpen} handleClose={handleCloseModal} info={modalInfo} />
    </Box>
  );
};

export default ScholarArticles;

import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';

const UpcomingEventsForm = ({ formData = {}, handleSaveSection }) => {
  const [nombreEvento, setNombreEvento] = useState('');
  const [fechaEvento, setFechaEvento] = useState('');
  const [horaEvento, setHoraEvento] = useState('');
  const [lugarEvento, setLugarEvento] = useState('');

  useEffect(() => {
    if (formData?.nombreEvento) {
      setNombreEvento(formData.nombreEvento);
    }
    if (formData?.fechaEvento) {
      setFechaEvento(formData.fechaEvento);
    }
    if (formData?.horaEvento) {
      setHoraEvento(formData.horaEvento);
    }
    if (formData?.lugarEvento) {
      setLugarEvento(formData.lugarEvento);
    }
  }, [formData]);

  const handleSave = () => {
    handleSaveSection('proximos_eventos', {
      nombreEvento,
      fechaEvento,
      horaEvento,
      lugarEvento,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Próximos Eventos
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nombre del Evento"
          value={nombreEvento}
          onChange={(e) => setNombreEvento(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Fecha"
          value={fechaEvento}
          onChange={(e) => setFechaEvento(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Hora"
          value={horaEvento}
          onChange={(e) => setHoraEvento(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Lugar"
          value={lugarEvento}
          onChange={(e) => setLugarEvento(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Guardar Evento
        </Button>
      </Grid>
    </Grid>
  );
};

export default UpcomingEventsForm;

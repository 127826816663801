import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Card, CardContent, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import InstitutionIcon from '@mui/icons-material/AccountBalance';
import StatusIcon from '@mui/icons-material/AssignmentTurnedIn';
import SpecialtyIcon from '@mui/icons-material/LocalHospital';
import DateIcon from '@mui/icons-material/Event';
import RoleIcon from '@mui/icons-material/Work';
import OrientadosIcon from '@mui/icons-material/People';
import TutorsIcon from '@mui/icons-material/SupervisorAccount';
import { useTranslation } from 'react-i18next';

import logos from '../assets/logos.json';
import pdfLinks from '../assets/pdfLinks.json'; 
import InfoModal from './modal';

// Styled components for card and header
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.05)',
  },
  transition: 'transform 0.3s ease-in-out',
  backgroundColor: '#f9f9f9',
  height: '100%',
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(1),
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  transition: 'all 0.3s ease-in-out',
  height: '48px', // Ensure height to show two lines
  '&:hover': {
    whiteSpace: 'normal',
    overflow: 'visible',
    textOverflow: 'clip',
    backgroundColor: theme.palette.primary.dark,
    height: 'auto', // Ensure it expands to fit content
  },
}));

const LogoImage = styled('img')({
  height: 50, // Fixed height
  width: 200, // Fixed width
  objectFit: 'contain', // Ensure image scales to fit within the dimensions without distortion
  marginLeft: 10,
  verticalAlign: 'middle',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
  },
});

const TrabajosDirigidosTutorias = ({ normalizedResearcher, searchTerm, handleSearchChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', summary: '', pdfUrl: '', pdfLink: '' });
  const { t } = useTranslation();

  const handleOpenModal = (info) => {
    setModalInfo(info);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Función para ordenar los trabajos por fecha
  const sortedWorks = [...normalizedResearcher.trabajos_dirigidos_tutorias].sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  // Función para filtrar los trabajos por el término de búsqueda
  const filteredWorks = sortedWorks.filter((work) =>
    work.titulo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getInstitutionData = (name) => {
    const institution = logos.universities.find((uni) => uni.name.toLowerCase() === name.toLowerCase()) ||
      logos.hospitals.find((hospital) => hospital.name.toLowerCase() === name.toLowerCase());
    return institution;
  };

  const getPdfUrl = (title) => {
    const event = pdfLinks.events.find(event => event.name.toLowerCase() === title.toLowerCase());
    return event ? event.pdfUrl : '';
  };

  return (
    <Box className="page-content" sx={{ padding: 2 }}>
      <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>
        {t('directedWorkTutorials')}
      </Typography>

      {/* Input de búsqueda */}
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <TextField
          fullWidth
          label={t('searchByTitle')}
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      {filteredWorks.length > 0 ? (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {filteredWorks.map((work, index) => {
            // Check if all required fields are empty or invalid
            const isEmpty = !work.titulo && !work.institucion && !work.estado && !work.especialidad && !work.fecha && !work.rol && work.orientados.length === 0 && work.tutores.length === 0;
            if (isEmpty) return null; // Don't render the card if all fields are empty or invalid

            const institutionData = getInstitutionData(work.institucion);

            const handleClick = () => {
              const pdfUrl = getPdfUrl(work.titulo);
              handleOpenModal({
                title: work.titulo,
                summary: `${work.institucion}, ${work.estado}, ${work.especialidad}, ${new Date(work.fecha).toLocaleDateString()}, ${work.rol}, ${work.orientados.join(', ')}, ${work.tutores.join(', ')}`,
                pdfUrl: pdfUrl,
                pdfLink: pdfUrl,
              });
            };

            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <StyledCard onClick={handleClick}>
                  <TitleBox>
                    {work.titulo ? work.titulo.charAt(0).toUpperCase() + work.titulo.slice(1).toLowerCase() : t('noData')}
                  </TitleBox>
                  <Divider />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      <InstitutionIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('institution')}:</strong> {work.institucion ? work.institucion.charAt(0).toUpperCase() + work.institucion.slice(1).toLowerCase() : t('noData')}
                      <br />
                      <StatusIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('status')}:</strong> {work.estado ? work.estado.charAt(0).toUpperCase() + work.estado.slice(1).toLowerCase() : t('noData')}<br />
                      <SpecialtyIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('specialty')}:</strong> {work.especialidad ? work.especialidad.charAt(0).toUpperCase() + work.especialidad.slice(1).toLowerCase() : t('noData')}<br />
                      <DateIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('date')}:</strong> {work.fecha ? new Date(work.fecha).toLocaleDateString() : t('noData')}<br />
                      <RoleIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('role')}:</strong> {work.rol ? work.rol.charAt(0).toUpperCase() + work.rol.slice(1).toLowerCase() : t('noData')}<br />
                      <OrientadosIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('oriented')}:</strong> {work.orientados.length > 0 ? work.orientados.join(', ').toLowerCase() : t('noData')}<br />
                      <TutorsIcon sx={{ verticalAlign: 'bottom' }} /> <strong>{t('tutors')}:</strong> {work.tutores.length > 0 ? work.tutores.join(', ').toLowerCase() : t('noData')} <br />
                      <br />
                      {institutionData && (
                        <a href={institutionData.url} target="_blank" rel="noopener noreferrer">
                          <LogoImage src={institutionData.logo} alt={work.institucion} />
                        </a>
                      )}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography variant="body1" sx={{ marginTop: 2 }}>{t('noData')}</Typography>
      )}
      <InfoModal open={modalOpen} handleClose={handleCloseModal} info={modalInfo} />
    </Box>
  );
};

export default TrabajosDirigidosTutorias;

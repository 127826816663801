import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { db, auth, storage } from './firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { toast } from 'react-toastify';

// Crear thunk para obtener investigadores
export const fetchResearchers = createAsyncThunk('researchers/fetchResearchers', async () => {
  const querySnapshot = await getDocs(collection(db, 'researchers'));
  const researchers = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    researchers.push({ id: doc.id, ...data });
  });
  return researchers;
});

export const addResearcher = createAsyncThunk('researchers/addResearcher', async (newResearcher, { rejectWithValue }) => {
  try {
    console.log('Adding Researcher:', newResearcher);
    let imageUrl = '';
    if (newResearcher.informacion_personal.foto) {
      const imageRef = ref(storage, `researchers/${new Date().getTime()}_${newResearcher.informacion_personal.foto.name}`);
      await uploadBytes(imageRef, newResearcher.informacion_personal.foto);
      imageUrl = await getDownloadURL(imageRef);
    }

    const researcherData = {
      ...newResearcher,
      informacion_personal: {
        ...newResearcher.informacion_personal,
        foto: imageUrl
      }
    };

    const docRef = await addDoc(collection(db, 'researchers'), researcherData);
    console.log('Researcher Added:', { id: docRef.id, ...researcherData });
    return { id: docRef.id, ...researcherData };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateResearcher = createAsyncThunk('researchers/updateResearcher', async ({ id, sectionName, updatedData }, { rejectWithValue }) => {
  try {
    console.log('Updating section:', sectionName, 'for researcher:', id);
    const docRef = doc(db, 'researchers', id);
    const docSnapshot = await getDoc(docRef);
    const existingData = docSnapshot.data();

    let updatedSection = updatedData;

    if (sectionName === 'informacion_personal' && updatedData.foto) {
      let imageUrl = existingData.informacion_personal.foto;

      const imageRef = ref(storage, `researchers/${new Date().getTime()}_${updatedData.foto.name}`);
      await uploadBytes(imageRef, updatedData.foto);
      imageUrl = await getDownloadURL(imageRef);

      if (existingData.informacion_personal.foto) {
        const oldImageRef = ref(storage, existingData.informacion_personal.foto);
        await deleteObject(oldImageRef);
      }

      updatedSection = {
        ...updatedData,
        foto: imageUrl
      };
    }

    // Remove any fields that are undefined
    Object.keys(updatedSection).forEach(key => {
      if (updatedSection[key] === undefined) {
        delete updatedSection[key];
      }
    });

    await setDoc(docRef, { [sectionName]: updatedSection }, { merge: true });
    return { id, sectionName, updatedSection };
  } catch (error) {
    console.error('Error updating section:', error);
    return rejectWithValue(error.message);
  }
});


export const deleteResearcher = createAsyncThunk('researchers/deleteResearcher', async (id, { rejectWithValue }) => {
  try {
    const docRef = doc(db, 'researchers', id);
    const docSnapshot = await getDoc(docRef);
    const imageUrl = docSnapshot.data().informacion_personal.foto;

    await deleteDoc(docRef);

    if (imageUrl) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    }

    return id;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const login = createAsyncThunk('auth/login', async ({ email, password }) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  toast.success('Successfully logged in!');
  return userCredential.user;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await signOut(auth);
  toast.success('Successfully logged out!');
});

export const checkAuthState = createAsyncThunk('auth/checkAuthState', async (_, { dispatch }) => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setUser(user));
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
});


const navigationSlice = createSlice({
  name: 'navigation',
  initialState: { currentPath: '/' },
  reducers: {
    setPath(state, action) {
      state.currentPath = action.payload;
    },
  },
});

const researcherSlice = createSlice({
  name: 'researchers',
  initialState: { list: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResearchers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchResearchers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchResearchers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(`Error fetching researchers: ${action.error.message}`);
      })
      .addCase(addResearcher.fulfilled, (state, action) => {
        state.list.push(action.payload);
        toast.success('Researcher added successfully!');
      })
      .addCase(updateResearcher.fulfilled, (state, action) => {
        const { id, sectionName, updatedSection } = action.payload;
        const index = state.list.findIndex((researcher) => researcher.id === id);
        if (index !== -1) {
          state.list[index][sectionName] = updatedSection;
          toast.success('Researcher updated successfully!');
        }
      })
      .addCase(deleteResearcher.fulfilled, (state, action) => {
        state.list = state.list.filter((researcher) => researcher.id !== action.payload);
        toast.success('Researcher deleted successfully!');
      });
  },
});

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, status: 'idle', error: null },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(`Login failed: ${action.error.message}`);
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.status = 'idle';
      })
      .addCase(checkAuthState.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkAuthState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(checkAuthState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setPath } = navigationSlice.actions;
export const { setUser } = authSlice.actions;

const store = configureStore({
  reducer: {
    navigation: navigationSlice.reducer,
    researchers: researcherSlice.reducer,
    auth: authSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['auth.user'],
        ignoredActions: ['auth/login/fulfilled', 'auth/checkAuthState/fulfilled'],
      },
    }),
});

export default store;

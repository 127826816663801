import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const DirectedWorks = ({ formData = [], handleChange, handleAddField, handleSaveSection }) => {
  const [jsonInput, setJsonInput] = useState('');
  const data = Array.isArray(formData) ? formData : [];

  const handleJsonChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleJsonLoad = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (Array.isArray(parsedData)) {
        parsedData.forEach((work, index) => {
          if (!data[index]) {
            handleAddField('trabajos_dirigidos_tutorias');
          }
          handleChange('trabajos_dirigidos_tutorias', 'tipo', work.tipo || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'titulo', work.titulo || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'institucion', work.institucion || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'estado', work.estado || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'especialidad', work.especialidad || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'fecha', work.fecha || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'rol', work.rol || 'sin datos', index);
          handleChange('trabajos_dirigidos_tutorias', 'orientados', work.orientados || [], index);
          handleChange('trabajos_dirigidos_tutorias', 'tutores', work.tutores || [], index);
        });
      } else {
        console.error('JSON debe ser un array.');
      }
    } catch (error) {
      console.error('Invalid JSON', error);
    }
  };

  const handleSave = () => {
    const cleanedData = data.map(item => ({
      tipo: item.tipo || 'sin datos',
      titulo: item.titulo || 'sin datos',
      institucion: item.institucion || 'sin datos',
      estado: item.estado || 'sin datos',
      especialidad: item.especialidad || 'sin datos',
      fecha: item.fecha || 'sin datos',
      rol: item.rol || 'sin datos',
      orientados: item.orientados || [],
      tutores: item.tutores || []
    }));
    handleSaveSection('trabajos_dirigidos_tutorias', cleanedData);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Trabajos Dirigidos/Tutorías
        </Typography>
        <Divider />
      </Grid>
      {data.map((work, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tipo"
              value={work.tipo || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'tipo', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título"
              value={work.titulo || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'titulo', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Institución"
              value={work.institucion || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'institucion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Estado (Tesis en curso/ Tesis concluida)"
              value={work.estado || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'estado', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Especialidad"
              value={work.especialidad || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'especialidad', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={work.fecha || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'fecha', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Rol (Tutor principal)"
              value={work.rol || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'rol', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Orientados"
              value={work.orientados?.join(', ') || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'orientados', e.target.value.split(', '), index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tutores"
              value={work.tutores?.join(', ') || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'tutores', e.target.value.split(', '), index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('trabajos_dirigidos_tutorias')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label="Trabajos Dirigidos/Tutorías JSON"
          value={jsonInput}
          onChange={handleJsonChange}
          placeholder="Ingresa el JSON aquí"
        />
        <Button variant="contained" color="primary" onClick={handleJsonLoad}>
          Procesar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Trabajos Dirigidos/Tutorías
        </Button>
      </Grid>
    </>
  );
};

export default DirectedWorks;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { researcherService } from '../services/researcherServices';
import { useTranslation } from 'react-i18next'; 

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
    textTransform: 'capitalize',
    fontWeight: 'bold',
}));

const ProductServiceItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[1],
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[3],
        transform: 'translateY(-2px)',
    },
}));

const OfertaProductosServicios = () => {
    const theme = useTheme();
    const { t } = useTranslation();  // Obtiene la función t para traducción
    const [oferta, setOferta] = useState([]);

    useEffect(() => {
        // Suscribirse al observable para recibir actualizaciones cuando cambie el investigador
        const subscription = researcherService.getResearcherObservable().subscribe((researcher) => {
            if (researcher) {
                const ofertaProductosServicios = researcherService.getProductServiceOffer();
                if (ofertaProductosServicios && ofertaProductosServicios.titulo && ofertaProductosServicios.texto) {
                    setOferta([ofertaProductosServicios]);
                } else {
                    setOferta([]); // Limpia la oferta si no hay datos válidos
                }
            }
        });

        // Limpiar la suscripción al desmontar el componente
        return () => subscription.unsubscribe();
    }, []);

    return (
        <Box>
            <SectionHeader variant="h6">{t('productServiceOffer')}</SectionHeader>  {/* Traducción aplicada aquí */}
            {oferta.map((item, index) => (
                <ProductServiceItem key={index}>
                    <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                        {item.titulo}
                    </Typography>
                    <Typography variant="body1">{item.texto}</Typography>
                </ProductServiceItem>
            ))}
        </Box>
    );
};

export default OfertaProductosServicios;

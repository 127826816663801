import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, Grid, Paper } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTranslation } from 'react-i18next';
import logos from '../assets/logos.json';

const ExperienceItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5), // Reducido para un diseño más compacto
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.5), // Reducido para compactar las tarjetas
  transition: 'all 0.3s ease-in-out',
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.secondary,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  width: '100%',
  boxShadow: theme.shadows[1],
  fontSize: '1rem',
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5), // Reducido para diseño compacto
}));

const capitalizeTitle = (title) => title.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());

const ProfessionalExperience = ({ experienciaProfesional }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const getInstitutionData = (name) => {
    return logos.universities.find(uni => uni.name.toLowerCase() === name.toLowerCase()) ||
      logos.hospitals.find(hospital => hospital.name.toLowerCase() === name.toLowerCase());
  };

  const filteredExperiencia = experienciaProfesional.filter(experience =>
    experience.institucion.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const order = ["CLINICA DE LA COSTA", "UNIVERSIDAD SIMÓN BOLÍVAR", "UNIVERSIDAD DEL NORTE"];

  const orderedExperiences = filteredExperiencia.sort((a, b) => {
    const indexA = order.indexOf(a.institucion.toUpperCase());
    const indexB = order.indexOf(b.institucion.toUpperCase());
    if (indexA === -1 && indexB === -1) return a.institucion.localeCompare(b.institucion);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  const groupedExperiences = orderedExperiences.reduce((acc, experience) => {
    if (!acc[experience.institucion]) acc[experience.institucion] = [];
    acc[experience.institucion].push(experience);
    return acc;
  }, {});

  return (
    <Box sx={{ padding: 1.5 }}> {/* Reducido el padding general */}
      <Typography variant="h5" sx={{ marginBottom: 1.5 }}> {/* Reducido el margen inferior */}
        {t('work')}
      </Typography>
      <Box sx={{ marginBottom: 1.5 }}> {/* Reducido el margen inferior */}
        <TextField
          fullWidth
          label="Buscar por Nombre de la Institución"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      {Object.keys(groupedExperiences).length > 0 ? (
        <Grid container spacing={1.5}> {/* Reducido el espacio entre grid items */}
          {Object.entries(groupedExperiences).map(([institution, experiences], index) => {
            const institutionData = getInstitutionData(institution);
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ExperienceItem>
                  <TitleBox>
                    <WorkIcon sx={{ marginRight: 1 }} />
                    <Typography variant="h6">{capitalizeTitle(institution)}</Typography>
                  </TitleBox>
                  {institutionData && (
                    <LogoBox>
                      <a href={institutionData.url} target="_blank" rel="noopener noreferrer">
                        <img src={institutionData.logo} alt={institution} style={{ height: 45, maxWidth: '100%' }} /> {/* Reducido el tamaño del logo */}
                      </a>
                    </LogoBox>
                  )}
                  <Timeline>
                    {experiences.map((experience, idx) => (
                      <TimelineItem key={idx}>
                        <TimelineSeparator>
                          <TimelineDot />
                          {idx < experiences.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="body2">
                            <strong>Dedicación:</strong> {experience.dedicacion}<br />
                            <strong>Fecha:</strong> {experience.fecha_inicio} - {experience.fecha_fin || 'Actual'}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </ExperienceItem>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography variant="body1">No hay datos</Typography>
      )}
    </Box>
  );
};

export default ProfessionalExperience;

import React from 'react';
import { Box, Typography, Grid, createTheme, ThemeProvider } from '@mui/material';
import { styled } from '@mui/system';
import { FaGoogle, FaLinkedin, FaResearchgate, FaUniversity, FaOrcid } from 'react-icons/fa';
import ActionAreas from './ActionAreas';
import Languages from './Languages';
import ResearchLines from './ResearchLines';
import ProductosResultadoInvestigacion from './ProductosResultadoInvestigacion';
import OfertaProductosServicios from './OfertaProductosServicios';
import ProximosEventos from './ProximosEventos';
import RedesColaboracion from './RedesColaboracion';
import { useTranslation } from 'react-i18next';

const ScopusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M64 64v384h384V64Zm116.815 99.926c22.222 0 32.04 2.586 45.736 8.27l-1.292 20.411c-14.729-8.785-26.356-11.367-43.152-11.367c-19.379 0-29.2 14.727-29.2 28.163c0 18.088 17.313 24.807 33.592 34.626c20.93 12.403 42.636 23.514 42.636 48.062c0 32.299-27.65 48.577-54.006 48.577c-18.863 0-32.557-2.843-45.993-9.044l3.102-20.414c13.178 8.01 24.547 11.889 41.86 11.889c17.57 0 32.297-11.887 32.297-28.424c0-17.054-16.535-23.516-32.297-33.076c-21.189-12.92-44.444-24.29-44.444-50.646s19.379-47.027 51.161-47.027m161.705 0c26.097 0 37.725 3.102 51.937 9.82l-1.55 19.38c-15.504-8.527-31.783-11.886-52.971-11.886c-33.592 0-62.274 26.613-62.274 69.765c0 40.826 29.2 71.575 65.892 71.575c16.795 0 33.591-3.359 49.353-11.886l1.55 19.638c-13.953 6.977-31.523 9.82-52.71 9.82c-42.12 0-87.338-31.01-87.338-87.597c0-49.612 37.982-88.63 88.11-88.63"
      opacity="0.999"
    />
  </svg>
);

const iconMapping = {
  google_scholar: FaGoogle,
  researchgate: FaResearchgate,
  linkedin: FaLinkedin,
  academia: FaUniversity,
  scopus: ScopusIcon,
  orcid: FaOrcid,
};

const getIcon = (url) => {
  if (url.includes('linkedin')) return FaLinkedin;
  if (url.includes('scholar.google')) return FaGoogle;
  if (url.includes('researchgate')) return FaResearchgate;
  if (url.includes('academia')) return FaUniversity;
  if (url.includes('scopus')) return ScopusIcon;
  if (url.includes('orcid')) return FaOrcid;
  return null;
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#4C3BCF',
    },
    secondary: {
      main: '#f0f0f0',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontWeight: 500,
      color: '#333333',
    },
    h6: {
      fontWeight: 500,
      color: '#333333',
    },
    body1: {
      fontSize: '1rem',
      color: '#666666',
    },
  },
});

const SectionHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  padding: theme.spacing(0.4), // Reducir padding
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0.8), // Reducir margen inferior
  textTransform: 'capitalize',
  fontWeight: 'bold',
}));

const InfoBlock = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5), // Reducir margen inferior
  padding: theme.spacing(0.75), // Reducir padding
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[3],
    transform: 'translateY(-2px)',
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 45, // Reducir tamaño
  height: 45, // Reducir tamaño
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
  margin: theme.spacing(0.4), // Reducir margen
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const GeneralInfo = ({ researcher }) => {
  const { t } = useTranslation();

  if (!researcher) {
    return <Typography variant="h6">{t('noData')}</Typography>;
  }

  const { biografia, informacion_personal, redes_sociales_academicas, identificadores_de_autor, idiomas } = researcher;

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="page-content"
        sx={{
          padding: 1.5, // Reducir padding general
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Typography
          variant="h5"
          className="page-title"
          sx={{
            backgroundColor: theme.palette.grey[200],
            padding: 0.8, // Reducir padding
            borderRadius: 1,
            '&:hover': { backgroundColor: theme.palette.primary.light },
            transition: 'background-color 0.3s ease-in-out',
          }}
        >
          {t('generalInfo')}
        </Typography>

        {biografia && biografia.texto && (
          <InfoBlock>
            <SectionHeader variant="h6" gutterBottom>{t('biography')}</SectionHeader>
            <Typography variant="body1">{biografia.texto}</Typography>
          </InfoBlock>
        )}

        <Grid container spacing={1.5} sx={{ marginTop: 1.5 }}>
          <Grid item xs={12} md={6}>
            <InfoBlock>
              <SectionHeader variant="h6" gutterBottom>{t('personalInfo')}</SectionHeader>
              <Typography variant="body1"><strong>{t('citationName')}:</strong> {informacion_personal?.nombre_en_citaciones || t('noData')}</Typography>
              <Typography variant="body1"><strong>{t('sex')}:</strong> {informacion_personal?.sexo || t('noData')}</Typography>
              <Typography variant="body1"><strong>{t('nationality')}:</strong> {informacion_personal?.nacionalidad || t('noData')}</Typography>
              <Typography variant="body1"><strong>{t('birthPlace')}:</strong> {informacion_personal?.lugar_nacimiento || t('noData')}</Typography>
            </InfoBlock>
          </Grid>

          <Grid item xs={12} md={6}>
            <InfoBlock>
              <SectionHeader variant="h6" gutterBottom>{t('academicSocialNetworksAndIdentifiers')}</SectionHeader>
              <Box display="flex" flexWrap="wrap">
                {Object.entries(redes_sociales_academicas).map(([key, value]) => {
                  const IconComponent = iconMapping[key] || getIcon(value);
                  return (
                    <IconContainer key={key}>
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        {IconComponent && <IconComponent size={22} />} {/* Reducir tamaño del icono */}
                      </a>
                    </IconContainer>
                  );
                })}
                {identificadores_de_autor?.autor_id_scopus && (
                  <IconContainer>
                    <a href={identificadores_de_autor.autor_id_scopus} target="_blank" rel="noopener noreferrer">
                      <ScopusIcon />
                    </a>
                  </IconContainer>
                )}
                {identificadores_de_autor?.orcid && (
                  <IconContainer>
                    <a href={identificadores_de_autor.orcid} target="_blank" rel="noopener noreferrer">
                      <FaOrcid size={22} /> {/* Reducir tamaño del icono */}
                    </a>
                  </IconContainer>
                )}
              </Box>
            </InfoBlock>
          </Grid>

          <Grid item xs={12}>
            <SectionHeader variant="h6" gutterBottom>{t('researchProductsAndServices')}</SectionHeader>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={6}>
                <ProductosResultadoInvestigacion />
              </Grid>
              <Grid item xs={12} md={6}>
                <OfertaProductosServicios />
                <ProximosEventos />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ResearchLines lines={[]} />
          </Grid>

          <Grid item xs={12}>
            <RedesColaboracion />
          </Grid>

          <Grid item xs={12}>
            <Languages languages={idiomas} />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default GeneralInfo;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { researcherService } from '../services/researcherServices';
import { useTranslation } from 'react-i18next';

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: 'bold',
}));

const ProductItemContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: '#f5f5f5',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
}));

const ProductValue = styled(Typography)(({ theme, color }) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: color,
    marginRight: theme.spacing(2),
}));

const ProductLabel = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 'normal',
    flexGrow: 1,
}));

const getColor = (key) => {
    switch (key) {
        case 'Artículo - Publicado en revista especializada':
            return '#4CAF50'; // Green
        case 'Artículo - Corto (Resumen)':
            return '#FF9800'; // Orange
        case 'Artículo - Revisión (Survey)':
            return '#2196F3'; // Blue
        case 'Artículo - Caso clínico':
            return '#F44336'; // Red
        default:
            return '#9E9E9E'; // Grey
    }
};

const ProductosResultadoInvestigacion = () => {
    const { t } = useTranslation();
    const [productions, setProductions] = useState([]);

    useEffect(() => {
        // Suscribirse al observable para recibir actualizaciones
        const subscription = researcherService.getResearcherObservable().subscribe((researcher) => {
            if (researcher) {
                const produccionBibliografica = researcherService.getProduccionBibliografica();
                setProductions(produccionBibliografica);
            }
        });

        // Limpiar la suscripción al desmontar el componente
        return () => subscription.unsubscribe();
    }, []);

    const countByCategory = (products) => {
        return products.reduce((acc, product) => {
            const category = product.tipo_produccion;
            if (acc[category]) {
                acc[category] += 1;
            } else {
                acc[category] = 1;
            }
            return acc;
        }, {});
    };

    const categorizedProducts = countByCategory(productions);

    // Prepare data for chart
    const chartData = Object.entries(categorizedProducts).map(([key, value]) => ({
        name: key,
        count: value,
        fill: getColor(key),
    }));

    return (
        <Box>
            <SectionHeader variant="h6">{t('researchProductsAndServices')}</SectionHeader>
            <Grid container spacing={2}>
                {Object.entries(categorizedProducts).map(([key, value], index) => (
                    <ProductItemContainer item xs={12} sm={6} key={index}>
                        <ProductValue color={getColor(key)}>{value}</ProductValue>
                        <ProductLabel>{key}</ProductLabel>
                    </ProductItemContainer>
                ))}
            </Grid>

            {/* Chart section */}
            <Box sx={{ marginTop: 4 }}>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={chartData} layout="vertical">
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="name" width={200} />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default ProductosResultadoInvestigacion;

import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';

const AlliancesCooperationForm = ({ formData = {}, handleSaveSection }) => {
  const [nacionales, setNacionales] = useState([]);
  const [internacionales, setInternacionales] = useState([]);
  const [newNacional, setNewNacional] = useState('');
  const [newInternacional, setNewInternacional] = useState('');

  useEffect(() => {
    if (formData?.nacionales) {
      setNacionales(formData.nacionales);
    }
    if (formData?.internacionales) {
      setInternacionales(formData.internacionales);
    }
  }, [formData]);

  const handleAddNacional = () => {
    if (newNacional) {
      setNacionales((prev) => [...prev, newNacional]);
      setNewNacional('');
    }
  };

  const handleAddInternacional = () => {
    if (newInternacional) {
      setInternacionales((prev) => [...prev, newInternacional]);
      setNewInternacional('');
    }
  };

  const handleSave = () => {
    handleSaveSection('alianzas_cooperacion', { nacionales, internacionales });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Alianzas y Cooperación
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Nacionales</Typography>
        {nacionales.map((item, index) => (
          <Typography key={index}>{item}</Typography>
        ))}
        <TextField
          fullWidth
          label="Nueva Alianza Nacional"
          value={newNacional}
          onChange={(e) => setNewNacional(e.target.value)}
        />
        <Button onClick={handleAddNacional} variant="contained" color="secondary">
          Agregar Nacional
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Internacionales</Typography>
        {internacionales.map((item, index) => (
          <Typography key={index}>{item}</Typography>
        ))}
        <TextField
          fullWidth
          label="Nueva Alianza Internacional"
          value={newInternacional}
          onChange={(e) => setNewInternacional(e.target.value)}
        />
        <Button onClick={handleAddInternacional} variant="contained" color="secondary">
          Agregar Internacional
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Guardar Alianzas
        </Button>
      </Grid>
    </Grid>
  );
};

export default AlliancesCooperationForm;
